section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
@media (min-width: 600px)  {
    section {
        margin: 2em;
    }
}
h1 {
    width: 100%;
    margin: 0.5em;
    text-align: center;
}
section figure {
    box-sizing: border-box;
    margin: 0.3em;
    width: 15em;
    height: 15em;
    overflow: hidden;
    border: goldenrod 0.3em solid;
    box-shadow: 0.5em 0.5em 0.4em 0.1em lightsteelblue;
    border-radius: 50%;
}
section figure:last-of-type {
    border-color: indianred;
    box-shadow: 0.7em 0.7em 1.5em 0.01em brown;
}
section figure img {
    width: 112%;
    margin: -5.5%;
    cursor: pointer;
}
section figure:hover {
    box-shadow: 0.3em 0.3em 0.2em 0.1em black;
}
section figure.playing {
    border: gold 0.4em solid !important;
}
